import React from 'react';
import styled from 'styled-components';
import { Container } from 'ui';
import { renderHtml } from 'utils';
import { Stack } from '@tymate/margaret';
import NavLinks from './NavLinks';
import PaprworkLink from './PaprworkLink';

const Logotype = styled.span`
  text-transform: uppercase;
  font-weight: 300;

  > span {
    font-weight: 700;
  }
`;

const FooterBase = styled.footer`
  background-color: ${({ theme }) => theme.primary};
  padding-top: ${({ theme }) => theme.spacing(3)};
  padding-bottom: ${({ theme }) => theme.spacing(3)};
  color: #ffffff;

  a {
    color: inherit;
    text-decoration: none;
  }
`;

const AnchorLink = styled.a`
  color: white;
  text-decoration: none;
`;

const Info = styled(Stack).attrs({
  direction: 'column',
  gutterSize: 0.5,
})``;

const InfoDesigned = styled(Stack).attrs({
  direction: 'column',
})`
  line-height: 1.5;
  max-width: 320px;
`;

const Footer = ({ globalInfo }) => (
  <FooterBase>
    <Container size="full">
      <Stack direction={{ default: 'column', desktop: 'row' }} gutterSize={2}>
        <Info>
          <Logotype>
            <span>Urbis</span> Avocats
          </Logotype>
          <div>
            {renderHtml(globalInfo?.adressNode?.childMarkdownRemark?.htmlAst)}
          </div>
          <InfoDesigned>
            Conçu et développé par Tymate,{''}
            <AnchorLink
              target="_blank"
              rel="noopener"
              href="https://tymate.com"
            >
              Agence de conception et développement à Lille
            </AnchorLink>
          </InfoDesigned>
        </Info>
        <Stack style={{ flex: 1 }}>
          <NavLinks />
        </Stack>
        <PaprworkLink />
      </Stack>
    </Container>
  </FooterBase>
);

export default Footer;
