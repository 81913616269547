import styled, { css } from 'styled-components';
import { Box, media, Container as MgtContainer } from '@tymate/margaret';
import { fontStyles } from './theme';

export const Title = styled(Box).attrs(({ as }) => ({
  as: as || 'h2',
  marginTop: 0,
  marginBottom: 1,
}))`
  ${fontStyles.h1};

  padding-left: ${({ theme }) => theme.spacing()};
  position: relative;
  text-transform: uppercase;

  &:before {
    position: absolute;
    content: '';
    display: block;
    width: 6px;
    background-color: ${({ theme }) => theme.primary};
    left: 0;
    top: ${({ theme }) => theme.spacing(0.25)};
    bottom: ${({ theme }) => theme.spacing(0.25)};
  }

  ${({ variant }) =>
    variant === 'light' &&
    css`
      color: #fff;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    `}
`;

export const Subtitle = styled(Box)`
  ${fontStyles.bodyLarge};

  color: ${({ theme }) => theme.textLight};
`;

export const Kicker = styled(Box)`
  ${fontStyles.bodyLarge};
  font-weight: 600;
`;

export const Legend = styled(Box)`
  ${fontStyles.bodySmall};
  color: ${({ theme }) => theme.textLighter};
`;

export const CardsList = styled.div`
  margin-top: ${({ theme }) => theme.spacing(1.5)};
  margin-bottom: ${({ theme }) => theme.spacing(1.5)};
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing(2)};

  ${({ variant }) =>
    variant === 'articles' &&
    media.tablet`
      grid-template-columns: repeat(2, 1fr);
    `}

  ${({ variant }) =>
    variant === 'auto' &&
    `
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    `}
`;

export const Container = styled(MgtContainer)`
  ${({ variant, theme }) =>
    variant === 'main' &&
    `
      padding-top: ${theme.spacing(4)};
    `}
`;
