import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { Stack, media } from '@tymate/margaret';

const NavLink = styled(Link)`
  font-weight: 600;
  text-decoration: none;
  color: #fff;
`;

const NavLinksBase = styled(Stack).attrs({
  gutterSize: { default: 0.5, tablet: 2 },
  alignX: { default: 'flex-start', tablet: 'center' },
  direction: { default: 'column', tablet: 'row' },
})`
  flex: 1;

  ${({ variant }) =>
    variant === 'header' &&
    css`
      ${NavLink} {
        color: ${({ theme }) => theme.primary};

        ${media.tablet`
          color: #ffffff;

          &.home-link {
            display: none;
          }
        `}
      }
    `}
`;

const NavLinks = ({ variant }) => (
  <NavLinksBase variant={variant}>
    <NavLink to="/" className="home-link">
      Accueil
    </NavLink>
    <NavLink to="/competences">Compétences</NavLink>
    <NavLink to="/blog">Notre blog</NavLink>
  </NavLinksBase>
);

export default NavLinks;
