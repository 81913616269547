import React from 'react';
import styled from 'styled-components';
import { useMargaret, Button, Stack } from '@tymate/margaret';
import paprwork from 'images/paprwork.svg';
import paprworkBlack from 'images/paprwork-black.svg';

const PaprworkLinkBase = styled(Stack).attrs({
  direction: 'column',
  gutterSize: 0.5,
  alignX: 'center',
})`
  color: ${({ theme }) => theme.primary};
`;

const PoweredBy = styled.span`
  color: ${({ theme }) => theme.textLighter};
  font-size: 10px;
`;

const PaprworkLogo = styled.img`
  display: block;
  width: auto;
  height: 14px;
`;

const PaprworkLink = ({ variant, ...props }) => {
  const { isMobile } = useMargaret();

  return (
    <Stack>
      <PaprworkLinkBase>
        <Button
          variant="primaryOnPrimary"
          as="a"
          href="https://v2.paprwork.io"
          target="blank"
          rel="noopener noreferer"
          {...props}
        >
          Espace client
        </Button>
        <Stack gutterSize={0.25}>
          <PoweredBy>propulsé par</PoweredBy>
          <PaprworkLogo
            src={isMobile && variant === 'header' ? paprworkBlack : paprwork}
            alt="Paprwork"
          />
        </Stack>
      </PaprworkLinkBase>
    </Stack>
  );
};

export default PaprworkLink;
