import React from 'react';
import styled from 'styled-components';
import { Stack, media, useMargaret } from '@tymate/margaret';
import logo from 'images/logo-header.svg';
import logoPrimary from 'images/logo-primary.svg';
import { Link } from 'gatsby';
import NavLinks from './NavLinks';
import PaprworkLink from './PaprworkLink';
import { MainNav as MgtMainNav } from '@tymate/margaret';

const MainNav = styled(MgtMainNav)`
  padding: ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing()}
    ${({ theme }) => theme.spacing()};

  ${media.tablet`
    padding: ${({ theme }) => theme.spacing(1.5)};
  `}
`;

const LogoLink = styled(Link)`
  position: absolute;
  bottom: ${({ theme }) => theme.spacing(2)};
  left: 50%;
  transform: translateX(-50%);

  ${media.tablet`
    position: static;
    transform: none;
    width: 9em;
  `}
`;

const Logo = styled.img`
  max-height: 96px;
  width: auto;
  display: block;

  ${media.tablet`
    max-height: 64px;
  `}
`;

const Trigger = styled.div`
  position: absolute;
  top: 0;
  z-index: 2;
  color: ${({ theme }) => theme.primary};

  ${({ variant }) =>
    variant === 'hero' &&
    `
      color: #ffffff;
    `}

  ${media.tablet`
    display: none;
  `}
`;

const Header = ({ variant }) => {
  const { isMobile } = useMargaret();

  return (
    <>
      <MainNav variant={variant}>
        <Stack
          direction={{ default: 'column', tablet: 'row' }}
          gutterSize={2.5}
          alignY="center"
          size="full"
        >
          <LogoLink to="/">
            <Logo src={isMobile ? logoPrimary : logo} alt="Urbis" />
          </LogoLink>

          <Stack style={{ flex: 1 }}>
            <NavLinks variant="header" />
          </Stack>

          <PaprworkLink variant="header" />
        </Stack>
      </MainNav>

      <Trigger variant={variant}>
        <MainNav.Trigger />
      </Trigger>
    </>
  );
};

export default Header;
