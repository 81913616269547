import React from 'react';
import styled, { css } from 'styled-components';
import { fontStyles, Legend } from '../ui';
import { Box, Stack } from '@tymate/margaret';
import { Link } from 'gatsby';
import { MdChevronRight } from 'react-icons/md';
import { formatDate } from 'utils';

export const Card = styled(Box)`
  background-color: #ffffff;
  position: relative;
  box-shadow: 0 12px 12px rgba(0, 0, 0, 0.12);
  text-decoration: none;
  color: inherit;

  ${({ to }) =>
    Boolean(to) &&
    css`
      &:hover ${MoreLinkBase} {
        color: ${({ theme }) => theme.secondary};
      }
    `}
`;

const CardTitle = styled(Box)`
  ${fontStyles.bodyLarge}
  font-weight: 600;
`;

const MoreLinkBase = styled(Link)`
  display: block;
  color: ${({ theme }) => theme.primary};
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing()} ${({ theme }) => theme.spacing(0.5)} 0;
  margin-bottom: ${({ theme }) => theme.spacing(1.5)};
  border-bottom: 1px solid currentColor;
  text-decoration: none;

  &:hover,
  &:active {
    color: ${({ theme }) => theme.secondary};
  }
`;

const CardMoreLink = ({ to, children, ...props }) => (
  <Stack size="full" alignX="flex-end">
    <MoreLinkBase to={to} {...props}>
      <Stack direction="row" alignY="center">
        <span>
          <MdChevronRight size={24} />
        </span>
        <span>{children}</span>
      </Stack>
    </MoreLinkBase>
  </Stack>
);

const CardTimestamp = ({ timestamp }) => (
  <Legend>{formatDate(timestamp, 'd MMMM yyyy')}</Legend>
);

Card.Title = CardTitle;
Card.MoreLink = CardMoreLink;
Card.Timestamp = CardTimestamp;

export default Card;
